@charset "UTF-8";
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  scroll-padding-top: 4rem;
}

body {
  font-family: "Montserrat", sans-serif;
  width: 100%;
}

.spy {
  color: #dbd513 !important;
}

.box-shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.section-padding {
  padding: 4rem 2.5rem;
}

.wrapper {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.gray_section {
  background-color: #f5f5f5;
  position: relative;
  padding: 12rem 2.5rem 6rem 2.5rem;
}

.section_baner {
  top: -3.5rem;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  width: 80%;
  height: 7rem;
  background-color: #dbd513;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
}

.section_heading-item {
  position: relative;
  background-color: white;
  display: inline-block;
  padding: 0.5rem;
}

.section_heading-item::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 180%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dbd513;
  z-index: -10;
}

.section_heading {
  text-align: center;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 6rem;
}

.mobile-nav button {
  background-color: transparent;
  border: none;
  outline: none;
}

.burger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 4rem;
  height: 4rem;
  padding: 1rem 0.8rem;
  cursor: pointer;
}
.burger-icon .bar {
  background-color: rgb(225, 225, 225);
  width: 100%;
  height: 2px;
  transition: opacity 0.3s, transform 0.3s;
}

.burger-icon.active .bar {
  background-color: #000;
}

.burger-icon.active .bar:nth-child(2) {
  opacity: 0;
}

.burger-icon.active .bar:nth-child(3) {
  transform: translateY(-6.8px) rotate(45deg);
}

.burger-icon.active .bar:nth-child(1) {
  transform: translateY(6.8px) rotate(-45deg);
}

.social {
  position: fixed;
  right: 0;
  z-index: 100;
  width: 12rem;
  height: 4rem;
  color: white;
  transform: translateX(70%);
  text-decoration: none;
  transition: transform 0.3s;
  display: none;
}
.social .social_img {
  width: 30%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.social .social_title {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.social:hover {
  transform: translateX(0);
}

.facebook {
  color: #1b80e4;
  top: calc(80% + 4.5rem);
}

.facebook_img {
  background-image: url("../../dist/img/facebook.png");
}

.insta {
  color: #e91dc4;
  top: 80%;
}

.insta_img {
  background-image: url("../../dist/img/insta.png");
}

/* HOVERY CTA */
.btn_hover {
  overflow: hidden;
  position: relative;
}

.btn_hover::before {
  content: "";
  position: absolute;
  left: -30%;
  top: 0%;
  transform: skew(-35deg);
  width: 20%;
  height: 100%;
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  transition: transform 0.8s;
}

.btn_hover:hover::before {
  transform: translate(650%);
}

nav.visible .navbar .logo a {
  color: #000;
}

.visible {
  background-color: #fff;
}

nav.nav-offset .navbar .logo a {
  color: #000;
}

nav.nav-offset .burger-icon .bar {
  background-color: #000;
}

.nav-offset {
  position: fixed;
  top: 0;
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7rem;
  background-color: transparent;
  z-index: 110;
  transition: all 0.3s;
}

.navbar {
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
  z-index: 100;
}
.navbar .logo {
  display: flex;
}
.navbar .logo a {
  background-image: url("../../dist/img/logo-removebg-preview.png");
  height: 4rem;
  width: 12rem;
  background-position: center;
  background-size: cover;
}
.navbar .mobile-nav {
  position: relative;
  width: 100%;
  display: flex;
}
.navbar .mobile-nav .burger-icon {
  z-index: 110;
  margin-left: auto;
}
.navbar .mobile-nav .nav-menu {
  position: fixed;
  right: -50rem;
  top: 0;
  width: 60%;
  height: 100vh;
  background-color: #fff;
  transition: 0.3s;
}
.navbar .mobile-nav .nav-menu .nav-list {
  margin-top: 7rem;
  list-style: none;
}
.navbar .mobile-nav .nav-menu .nav-list a {
  display: none;
  opacity: 0;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
}
.navbar .mobile-nav .nav-menu .nav-list a li {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  font-size: 1.5rem;
  padding: 2rem;
}
.navbar .mobile-nav .nav-menu .nav-list a li i {
  font-size: 1.7rem;
}
.navbar .mobile-nav .nav-menu .nav-list::after,
.navbar .mobile-nav .nav-menu .nav-list::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 90%;
  height: 1px;
  background-color: black;
}
.navbar .mobile-nav .nav-menu .nav-miniContact {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.navbar .mobile-nav .nav-menu.active {
  right: 0;
}
.navbar .mobile-nav .nav-menu.active .nav-list a {
  opacity: 1;
  display: flex;
}

.nav-contact {
  display: none;
  gap: 2rem;
  height: 3rem;
  width: 100%;
  z-index: 110;
  border-bottom: 1px solid #000;
}
.nav-contact i {
  font-size: 1.3rem;
}
.nav-contact p {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: 0.5rem;
}
.nav-contact p a {
  color: white;
  text-decoration: none;
}

.desktop-nav {
  display: none;
  width: 100%;
}
.desktop-nav .dnav-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.desktop-nav .dnav-list li {
  min-width: max-content;
  font-size: 1.6rem;
  transition: transform 0.3s;
}
.desktop-nav .dnav-list li a {
  padding: 1rem;
  text-decoration: none;
  color: black;
}
.desktop-nav .dnav-list li:hover {
  transform: scale(1.1);
}

header {
  height: 100vh;
  width: 100%;
}
header .bg-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../dist/img/esa.jpg");
  background-size: cover;
  background-position: center;
}
header .bg-image .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 3rem;
  text-align: center;
}
header .bg-image .content h1 {
  font-size: 3rem;
}
header .bg-image .content h1 span {
  color: #dbd513;
}
header .bg-image .content p {
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: rgb(225, 225, 225);
}
header .bg-image .content a {
  margin-top: 3rem;
  text-decoration: none;
  color: white;
  background-color: #dbd513;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 600;
  width: max-content;
}

.aboutus {
  opacity: 0;
}
.aboutus .aboutus_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6rem;
  margin-bottom: 4rem;
}
.aboutus .aboutus_content .aboutus_image {
  height: 100%;
  position: relative;
}
.aboutus .aboutus_content .aboutus_image img {
  width: 100%;
  max-height: 40rem;
}
.aboutus .aboutus_content .aboutus_image::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: -10px;
  right: -10px;
  background-color: #dbd513;
  z-index: -1;
}
.aboutus .aboutus_content .aboutus_text {
  line-height: 2.3rem;
  font-size: 1.5rem;
  color: black;
}

.stats {
  opacity: 0;
  padding: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 8.5rem;
  background-color: #dbd513;
}
.stats .stats_box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
.stats .stats_box .stats_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.stats .stats_box .stats_item .stat_value {
  font-size: 3rem;
  font-weight: bold;
}
.stats .stats_box .stats_item .stat_desc {
  font-weight: bold;
}

.details {
  opacity: 0;
  padding: 4rem 2.5rem 0;
}
.details .section_heading-item::before {
  width: 140%;
}
.details .details_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6rem;
  margin-bottom: 6rem;
}
.details .details_content .details_image {
  height: 100%;
  position: relative;
}
.details .details_content .details_image img {
  width: 100%;
  max-height: 40rem;
}
.details .details_content .details_image::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: -10px;
  left: -10px;
  background-color: #dbd513;
  z-index: -1;
}
.details .details_content .details_card {
  background-color: #efefef;
  padding: 2.5em;
  max-width: 35rem;
}
.details .details_content .details_card .details_card_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4rem;
}
.details .details_content .details_card li {
  list-style: none;
  font-size: 1.4rem;
}
.details .details_content .details_card .details_card_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  padding: 0.7rem;
  width: 100%;
  color: white;
  background-color: #dbd513;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: border-radius 0.3s, color 0.3s;
}
.details .details_content .details_card .details_card_btn:focus {
  border: 2px solid black;
}

.courses {
  opacity: 0;
  margin-top: 4rem;
}
.courses .section_baner {
  height: 5rem;
  top: -2.5rem;
}
.courses .section_baner .section_baner-item {
  font-weight: 600;
  font-size: 2rem;
}
.courses .courses_cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
}
.courses .courses_cards .course_card {
  background-color: #fff;
  border: 1px solid rgb(212, 212, 212);
  max-width: 50rem;
}
.courses .courses_cards .course_card .card_img {
  background-size: cover;
  background-position: center;
  height: 20rem;
}
.courses .courses_cards .course_card .card_one {
  background-image: url("../../dist/img/swift1.jpg");
}
.courses .courses_cards .course_card .card_two {
  background-image: url("../../dist/img/extended.jpg");
}
.courses .courses_cards .course_card .card_three {
  background-image: url("../../dist/img/extened2.jpg");
}
.courses .courses_cards .course_card .card_content {
  padding: 3rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.courses .courses_cards .course_card .card_content .card_heading {
  font-size: 2.2rem;
  margin-bottom: 2rem;
}
.courses .courses_cards .course_card .card_content .card_list {
  list-style: "✓";
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.4rem;
}

.offers {
  opacity: 0;
}
.offers .offers-cnt {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.offers .offers-cnt .section-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.offers .offers-cnt .section-title .section_heading {
  margin-bottom: 0;
}
.offers .offers-cnt .section-title .section_heading .section_heading-item::before {
  width: 140%;
}
.offers .offers-cnt .section-title p {
  font-size: 1.3rem;
  text-align: center;
  color: #494949;
}
.offers .offers-cnt .cards-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.offers .offers-cnt .cards-cnt .card {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
  min-height: 200px;
}
.offers .offers-cnt .cards-cnt .card .offer-img {
  width: 76.9230769231%;
  background-size: cover;
  background-position: center;
}
.offers .offers-cnt .cards-cnt .card .content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1.5rem;
}
.offers .offers-cnt .cards-cnt .card .content h3 {
  font-weight: 600;
  font-size: 1.7rem;
}
.offers .offers-cnt .cards-cnt .card .content p {
  font-size: 1.2rem;
}
.offers .offers-cnt .cards-cnt .card .content a {
  width: max-content;
  text-decoration: none;
  font-size: 1.4rem;
  margin-top: auto;
  background-color: #dbd513;
  color: white;
  padding: 0.8rem 2.5rem;
}

.katB {
  background-image: url("../../dist/img/swift2.jpg");
}

.katA {
  background-image: url("../../dist/img/katA.jpg");
}

.faq {
  opacity: 0;
  margin-top: 6rem;
}
.faq .section_baner {
  height: 5rem;
  top: -2.5rem;
}
.faq .section_baner .section_baner-item {
  font-weight: 600;
  font-size: 2rem;
}
.faq .accordions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}
.faq .accordions .accordion {
  width: 100%;
}
.faq .accordions .accordion .accordion_content {
  position: relative;
  color: #181818;
  background-color: rgb(255, 255, 255);
  padding: 1em 5em 1em 1em;
  width: 100%;
  font-size: 1.8rem;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none;
}
.faq .accordions .accordion .accordion_content::before {
  content: "+";
  position: absolute;
  right: 1rem;
  font-size: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #dbd513;
  padding: 0.1em;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq .accordions .accordion .accordion_content:focus {
  border: 1px solid #dbd513;
}
.faq .accordions .accordion .accordion_text {
  background: transparent;
  padding: 1em 2em;
  font-size: 1.4rem;
  display: none;
  text-align: left;
  opacity: 0;
  line-height: 2rem;
}
.faq .accordions .accordion .active {
  display: block;
  animation: accordion 1.2s forwards;
}
.faq .accordions .accordion .open_accordion {
  background-color: #dbd513;
  color: white;
}

.contact {
  opacity: 0;
}
.contact .contact-section-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.contact .contact-section-cnt .section_heading {
  margin-bottom: 0;
}
.contact .contact-section-cnt .section_heading .section_heading-item::before {
  width: 150%;
}
.contact .contact-section-cnt .contact-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.contact .contact-section-cnt .contact-cnt .contact-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.contact .contact-section-cnt .contact-cnt .contact-cards .card {
  box-shadow: 2px 2px 10px 0 rgba(66, 68, 90, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  min-height: 15rem;
  min-width: 25rem;
}
.contact .contact-section-cnt .contact-cnt .contact-cards .card .contact-title {
  margin-bottom: 1.5rem;
}
.contact .contact-section-cnt .contact-cnt .contact-cards .card p {
  font-size: 1.4rem;
  color: #535353;
}
.contact .contact-section-cnt .contact-cnt .contact-cards .card i {
  font-size: 1.7rem;
}
.contact .contact-section-cnt .contact-cnt .contact-cards .card .contact-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.3rem;
}
.contact .contact-section-cnt .contact-cnt .contact-cards .card .contact-title h3 {
  font-weight: 500;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 8rem;
  font-size: 1.4rem;
  background-color: #000;
  color: white;
  /* a:hover {
  	transform-origin: center;
  	transform: rotate(10deg);
  } */
}
footer .authors {
  display: flex;
  gap: 0.1rem;
}
footer .authors a {
  font-size: 1.3rem;
  text-decoration: none;
  color: #dbd513;
}
footer .footer-info {
  display: flex;
  align-items: center;
}
footer .color {
  color: #dbd513;
}
footer a {
  font-size: 2.5rem;
  margin-left: 1rem;
  color: white;
  transition: transform 0.3s;
}

/* warunki*/
.warunki {
  opacity: 0;
  margin-bottom: 7rem;
}
.warunki .section_heading {
  margin-bottom: 7rem;
}
.warunki .section_heading .section_heading-item::before {
  width: 110%;
}
.warunki .warunki-title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.warunki .warunki-list {
  font-size: 1.2rem;
  list-style: none;
}
.warunki .warunki-list li {
  margin-bottom: 0.5rem;
}
.warunki .idk {
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.warunki .niewiemlist {
  font-size: 1.2rem;
}
.warunki .niewiemlist li {
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  html {
    font-size: 68.75%;
  }
  .stats .stats_box .stats_item .stat_desc {
    font-size: 1.3rem;
  }
  .details .details_content {
    gap: 8rem;
  }
  .details .details_content .details_card {
    min-width: 40rem;
  }
  .details .details_content .details_card .details_card_list {
    gap: 1.2rem;
    margin-bottom: 4rem;
  }
  .details .details_content .details_card .details_card_btn {
    height: 4.2rem;
  }
  .courses .courses_cards .course_card .card_img {
    height: 20rem;
  }
  .courses .courses_cards .course_card .card_content .card_list {
    gap: 2rem;
  }
  .faq {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .navbar {
    height: 7rem;
    height: 7rem;
    padding: 1rem 3rem;
  }
  .navbar .mobile-nav .nav-menu .nav-list {
    margin-top: 7rem;
  }
  .navbar .mobile-nav .nav-menu .nav-list a li {
    gap: 1.3rem;
    padding: 2rem;
  }
  .navbar .mobile-nav .nav-menu .nav-miniContact {
    margin-top: 1rem;
    gap: 2rem;
    padding: 0.5rem;
  }
  header .bg-image .content {
    padding: 2.5rem;
  }
  .burger-icon.active .bar:nth-child(3) {
    transform: translateY(-7px) rotate(45deg);
  }
  .burger-icon.active .bar:nth-child(1) {
    transform: translateY(7px) rotate(-45deg);
  }
  .offers .offers-cnt .cards-cnt .card {
    max-width: 450px;
  }
  .contact .contact-section-cnt .contact-cnt .contact-cards {
    flex-direction: row;
    gap: 2rem;
  }
  .warunki .section_heading .section_heading-item::before {
    width: 130%;
  }
  .social {
    display: flex;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 75%;
    scroll-padding-top: 3rem;
  }
  .section_heading {
    margin-bottom: 8rem;
  }
  .aboutus {
    padding: 4rem 8rem;
  }
  .aboutus .aboutus_content {
    gap: 8rem;
  }
  .details .details_content .details_card {
    min-width: 40rem;
  }
  .details .details_content .details_card .details_card_btn {
    height: 4.4rem;
  }
  .faq {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .faq .accordions {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .faq .accordions .accordion {
    width: 40%;
  }
  nav {
    background-color: transparent;
    height: 10rem;
  }
  nav .navbar .logo a {
    color: white;
    display: flex;
  }
  nav .navbar .mobile-nav {
    display: none;
  }
  nav .navbar .desktop-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  nav .navbar .desktop-nav .dnav-list li a {
    color: white;
  }
  nav .nav-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-bottom: 1px solid white;
  }
  nav .nav-contact .nav-contact-item {
    font-size: 1rem;
    color: white;
  }
  nav.nav-offset {
    height: 5rem;
  }
  nav.nav-offset .navbar {
    height: 5rem;
  }
  nav.nav-offset .nav-contact {
    display: none;
  }
  nav.nav-offset .desktop-nav .dnav-list li a {
    color: #000;
  }
  header .bg-image .content {
    top: 55%;
    gap: 3rem;
  }
  header .bg-image .content p {
    padding: 0 2rem;
    font-size: 1.7rem;
  }
  .offers .offers-cnt .cards-cnt {
    flex-direction: row;
    justify-content: center;
  }
  .offers .offers-cnt .cards-cnt .card {
    max-width: 450px;
  }
  .contact .contact-section-cnt .contact-cnt .contact-cards {
    gap: 4rem;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 81.25%;
    scroll-padding-top: 4rem;
  }
  .section_baner {
    height: 8rem;
  }
  .aboutus {
    padding: 4rem 4rem;
  }
  .aboutus .aboutus_content {
    flex-direction: row-reverse;
    align-items: center;
  }
  .aboutus .aboutus_content .aboutus_image img {
    max-height: 50rem;
    max-width: 45rem;
  }
  .aboutus .aboutus_content .aboutus_text {
    width: 50%;
  }
  .details .details_content {
    flex-direction: row;
    gap: 8rem;
    align-items: center;
  }
  .details .details_content .details_image {
    max-width: 50%;
  }
  .details .details_content .details_card {
    min-width: 25rem;
    height: 100%;
  }
  .details .details_content .details_card .details_card_list {
    gap: 0.7rem;
  }
  .courses {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .courses .courses_cards .course_card {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    height: 25rem;
  }
  .courses .courses_cards .course_card .card_img {
    width: 50%;
    height: 100%;
  }
  .courses .courses_cards .course_card .card_content {
    align-items: flex-start;
    width: 50%;
    gap: 1rem;
  }
  .courses .courses_cards .course_card .card_content .card_heading {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .courses .courses_cards .course_card .card_content .card_list {
    list-style-position: inside;
    font-size: 1.1rem;
    color: #494949;
    gap: 1rem;
  }
  .courses .courses_cards .card_two {
    display: flex;
    flex-direction: row-reverse;
  }
  .faq {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .faq .accordions {
    gap: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .faq .accordions .accordion {
    width: 40%;
  }
  .nav-contact .nav-contact-item {
    font-size: 1.4rem;
  }
  header .bg-image .content {
    gap: 4rem;
  }
  header .bg-image .content p {
    padding: 0 10rem;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 87.5%;
  }
  .section_baner {
    top: -4rem;
    width: 60%;
    height: 8rem;
  }
  header .bg-image content {
    top: 60%;
  }
  .offers .offers-cnt .cards-cnt {
    gap: 6rem;
  }
  .offers .offers-cnt .cards-cnt .card {
    min-width: 35rem;
    min-height: 20rem;
  }
}
@keyframes accordion {
  to {
    opacity: 1;
    background-color: rgb(255, 255, 255);
  }
}
@keyframes reval {
  from {
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.reval {
  animation: reval 0.4s 0.3s forwards;
}